var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{ref:"searchFormRef",attrs:{"keyword-placeholder":"姓名、学号、录取编号","queryInfo":_vm.queryInfo,"tool-list":['grade', 'college', 'major', 'stuStatus','keyword'],"insert-select-all":['college', 'major']},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}}),_c('div',{staticClass:"header-button"},[_c('el-button',{attrs:{"type":"success","size":"small"},on:{"click":_vm.exportStudentInfo}},[_vm._v("导出该筛选条件学生")])],1)]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"type":"index","label":"ID","width":"50"}}),_c('el-table-column',{attrs:{"prop":"examineNo","label":"录取编号","width":"150"}}),_c('el-table-column',{attrs:{"prop":"stuName","label":"姓名","width":"120"}}),_c('el-table-column',{attrs:{"label":"学籍状态","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$http.getParamName(row.stuStatus, _vm.$store.state.systemParam.stuStatus))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"sexType","label":"性别","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(Number(row.sexType) === 1 ? '男' : '女')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"collegeName","label":"院系","width":"150"}}),_c('el-table-column',{attrs:{"prop":"majorName","label":"专业","min-width":"200"}}),_c('el-table-column',{attrs:{"prop":"className","label":"班级","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.className || '未绑定')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"armyClothesFlag","label":"军训服","width":"100","align":"center","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(Number(row.armyClothesFlag) === 1)?_c('span',[_vm._v("已领取")]):_c('span',{staticClass:"font-grey"},[_vm._v("未领取")])]}}])}),_c('el-table-column',{attrs:{"prop":"clothesFlag","label":"校服","width":"100","align":"center","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(Number(row.clothesFlag) === 1)?_c('span',[_vm._v("已领取")]):_c('span',{staticClass:"font-grey"},[_vm._v("未领取")])]}}])}),_c('el-table-column',{attrs:{"prop":"campusCardFlag","label":"校园卡","width":"100","align":"center","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(Number(row.campusCardFlag) === 1)?_c('span',[_vm._v("已领取")]):_c('span',{staticClass:"font-grey"},[_vm._v("未领取")])]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"100","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['stu:uniform:edit']),expression:"['stu:uniform:edit']"}],attrs:{"type":"text"},on:{"click":function($event){return _vm.showDialog(row)}}},[_vm._v("修改状态 ")])]}}])})],1),(_vm.visibleDialog)?_c('registerUniform',{ref:"dialogRef",attrs:{"visible":_vm.visibleDialog},on:{"update:visible":function($event){_vm.visibleDialog=$event},"on-close":_vm.renderTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }