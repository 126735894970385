<template>
  <el-dialog
    class="class-room-dialog"
    :title="`${preTitle}修改校服/校园卡领取状态`"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="cancel"
    width="520px"
  >
    <el-form ref="form" :model="formData" label-width="100px">
      <el-form-item label="军训服">
        <el-radio-group v-model="formData.armyClothesFlag">
          <el-radio-button :class="{'radio-btn-danger':formData.armyClothesFlag===0}" v-for="{name, type} in list"
                           :label="type" :key="type">{{ name }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="校服">
        <el-radio-group v-model="formData.clothesFlag">
          <el-radio-button :class="{'radio-btn-danger':formData.clothesFlag===0}" v-for="{name, type} in list"
                           :label="type" :key="type">{{ name }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="校园卡">
        <el-radio-group v-model="formData.campusCardFlag">
          <el-radio-button :class="{'radio-btn-danger':formData.campusCardFlag===0}" v-for="{name, type} in list"
                           :label="type" :key="type">{{ name }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save('校服/校园卡领取状态',addStudent)">保 存</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addStudent } from '@/api/student'
import dialog from '@/vue/mixins/dialog'

export default {
  props: {
    addData: {
      type: Object
    }
  },
  mixins: [dialog],
  data() {
    return {
      formData: {
        id: '',
        userId: '',
        armyClothesFlag: '', // 军训服
        clothesFlag: '', // 校服
        campusCardFlag: '' // 校园卡
      },
      list: [
        {
          type: 1,
          name: '已领取'
        },
        {
          type: 0,
          name: '未领取'
        }
      ]
    }
  },
  methods: {
    addStudent // 保存api
  }
}
</script>
<style lang="scss">
.class-room-dialog {
  .radio-btn-danger {
    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background: var(--color-danger);
      border-color: var(--color-danger);
      box-shadow: -1px 0 0 0 var(--color-danger);
    }
  }
}
</style>
