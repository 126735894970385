var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"class-room-dialog",attrs:{"title":(_vm.preTitle + "修改校服/校园卡领取状态"),"visible":_vm.show,"close-on-click-modal":false,"before-close":_vm.cancel,"width":"520px"},on:{"update:visible":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('cancel-popover',{ref:"cancelRef",attrs:{"disabled":_vm.loadData.loading,"update-flag":_vm.updateFlag},on:{"update:disabled":function($event){return _vm.$set(_vm.loadData, "loading", $event)}}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.save('校服/校园卡领取状态',_vm.addStudent)}}},[_vm._v("保 存")])]},proxy:true}])},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"军训服"}},[_c('el-radio-group',{model:{value:(_vm.formData.armyClothesFlag),callback:function ($$v) {_vm.$set(_vm.formData, "armyClothesFlag", $$v)},expression:"formData.armyClothesFlag"}},_vm._l((_vm.list),function(ref){
var name = ref.name;
var type = ref.type;
return _c('el-radio-button',{key:type,class:{'radio-btn-danger':_vm.formData.armyClothesFlag===0},attrs:{"label":type}},[_vm._v(_vm._s(name)+" ")])}),1)],1),_c('el-form-item',{attrs:{"label":"校服"}},[_c('el-radio-group',{model:{value:(_vm.formData.clothesFlag),callback:function ($$v) {_vm.$set(_vm.formData, "clothesFlag", $$v)},expression:"formData.clothesFlag"}},_vm._l((_vm.list),function(ref){
var name = ref.name;
var type = ref.type;
return _c('el-radio-button',{key:type,class:{'radio-btn-danger':_vm.formData.clothesFlag===0},attrs:{"label":type}},[_vm._v(_vm._s(name)+" ")])}),1)],1),_c('el-form-item',{attrs:{"label":"校园卡"}},[_c('el-radio-group',{model:{value:(_vm.formData.campusCardFlag),callback:function ($$v) {_vm.$set(_vm.formData, "campusCardFlag", $$v)},expression:"formData.campusCardFlag"}},_vm._l((_vm.list),function(ref){
var name = ref.name;
var type = ref.type;
return _c('el-radio-button',{key:type,class:{'radio-btn-danger':_vm.formData.campusCardFlag===0},attrs:{"label":type}},[_vm._v(_vm._s(name)+" ")])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }