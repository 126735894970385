<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        ref="searchFormRef"
        keyword-placeholder="姓名、学号、录取编号"
        :queryInfo.sync="queryInfo"
        :tool-list="['grade', 'college', 'major', 'stuStatus','keyword']"
        :insert-select-all="['college', 'major']"
        @on-search="renderTable(1)" />
      <div class="header-button">
        <el-button type="success" size="small" @click="exportStudentInfo">导出该筛选条件学生</el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="examineNo" label="录取编号" width="150" />
      <el-table-column prop="stuName" label="姓名" width="120" />
      <el-table-column label="学籍状态" width="110">
        <template v-slot="{ row }">
          {{ $http.getParamName(row.stuStatus, $store.state.systemParam.stuStatus) }}
        </template>
      </el-table-column>
      <el-table-column prop="sexType" label="性别" width="80">
        <template v-slot="{ row }">
          {{ Number(row.sexType) === 1 ? '男' : '女' }}
        </template>
      </el-table-column>
      <el-table-column prop="collegeName" label="院系" width="150" />
      <el-table-column prop="majorName" label="专业" min-width="200" />
      <el-table-column prop="className" label="班级" width="150">
        <template v-slot="{ row }">
          {{ row.className || '未绑定' }}
        </template>
      </el-table-column>
      <el-table-column prop="armyClothesFlag" label="军训服" width="100" align="center" fixed="right">
        <template v-slot="{ row }">
          <span v-if="Number(row.armyClothesFlag) === 1">已领取</span>
          <span class="font-grey" v-else>未领取</span>
        </template>
      </el-table-column>
      <el-table-column prop="clothesFlag" label="校服" width="100" align="center" fixed="right">
        <template v-slot="{ row }">
          <span v-if="Number(row.clothesFlag) === 1">已领取</span>
          <span class="font-grey" v-else>未领取</span>
        </template>
      </el-table-column>
      <el-table-column prop="campusCardFlag" label="校园卡" width="100" align="center" fixed="right">
        <template v-slot="{ row }">
          <span v-if="Number(row.campusCardFlag) === 1">已领取</span>
          <span class="font-grey" v-else>未领取</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" fixed="right">
        <template v-slot="{ row }">
          <el-button
            v-permission="['stu:uniform:edit']"
            type="text"
            @click="showDialog(row)"
          >修改状态
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <registerUniform ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable" />
  </table-view>
</template>

<script>
import { getStudentList } from '@/api/student'
import registerUniform from './dialog/registerUniform.vue'
import tableView from '@/vue/mixins/table-view'

export default {
  components: {
    registerUniform
  },
  mixins: [tableView],
  data() {
    return {
      queryInfo: {
        stuStatus: 1, // （0——未注册，1——在读，2——毕业）
        grade: new Date().getFullYear(),
        campusId: null,
        collegeId: null,
        majorId: null,
        classesId: null
      }
    }
  },
  methods: {
    // 获取学生列表
    async renderTable(pageNum) {
      await this.getTableData(getStudentList, pageNum)
    },
    // 修改学生校服/校园卡状态
    showDialog(row) {
      this.visibleDialog = true
      this.$nextTick(() => {
        this.$refs.dialogRef.preTitle = `${row.stuName}(${row.examineNo})：`
        Object.keys(this.$refs.dialogRef.formData).forEach(key => {
          this.$refs.dialogRef.formData[key] = row[key]
        })
        this.$refs.dialogRef.setInitData()
      })
    },
    // 导出学生名单
    exportStudentInfo() {
      let stuStatusName = ''
      if (this.queryInfo.stuStatus !== null && this.queryInfo.stuStatus !== '' &&
        this.$store.state.systemParam.stuStatus != null && this.$store.state.systemParam.stuStatus.length > 0) {
        this.$store.state.systemParam.stuStatus.forEach(item => {
          if (Number(item.paramValue) === Number(this.queryInfo.stuStatus)) {
            stuStatusName = item.paramName
          }
        })
      }
      this.$http.exportExcelHttp.exportStudentInfo(this.queryInfo, stuStatusName + '学生名单').then(res => {
      })
    }
  }
}
</script>
